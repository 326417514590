import img1 from '../assets/img/Guindastes/1.jpg';
import img2 from '../assets/img/Guindastes/2.jpg';
import img3 from '../assets/img/Guindastes/3.jpg';
import img4 from '../assets/img/Guindastes/4.jpg';
import img5 from '../assets/img/Guindastes/5.jpg';
import img6 from '../assets/img/Guindastes/6.jpg';
import img7 from '../assets/img/Guindastes/7.jfif';
import img8 from '../assets/img/Guindastes/8.jfif';
import img9 from '../assets/img/Guindastes/9.jfif';

const items = [
    {
      id: 1,
      image: img1,
      imageBg: img1,
      title: 'Carrinho bolado 01',
      text: 'Nossa frota são de guindastes novos e semi- novos de última geração, com operadores capacitados trazendo mais segurança aos nossos clientes.',
    },
    {
      id: 2,
      image: img2,
      imageBg: img2,
      title: 'sdfdghffhfghdfhhf 01',
      text: 'Nossa frota são de guindastes novos e semi- novos de última geração, com operadores capacitados trazendo mais segurança aos nossos clientes.',
    },
    {
      id: 3,
      image: img3,
      imageBg: img3,
      title: 'Carrinho 01',
      text: 'Nossa frota são de guindastes novos e semi- novos de última geração, com operadores capacitados trazendo mais segurança aos nossos clientes.',
    },
    {
      id: 4,
      image: img4,
      imageBg: img4,
      title: 'Carrinho 01',
      text: 'Nossa frota são de guindastes novos e semi- novos de última geração, com operadores capacitados trazendo mais segurança aos nossos clientes.',
    },
    {
      id: 5,
      image: img5,
      imageBg: img5,
      title: 'Carrinho 01',
      text: 'Nossa frota são de guindastes novos e semi- novos de última geração, com operadores capacitados trazendo mais segurança aos nossos clientes.',
    },
    {
      id: 6,
      image: img6,
      imageBg: img6,
      title: 'Carrinho 01',
      text: 'Nossa frota são de guindastes novos e semi- novos de última geração, com operadores capacitados trazendo mais segurança aos nossos clientes.',
    },
    {
      id: 7,
      image: img7,
      imageBg: img7,
      title: 'Carrinho 01',
      text: 'Nossa frota são de guindastes novos e semi- novos de última geração, com operadores capacitados trazendo mais segurança aos nossos clientes.',
    },
    {
      id: 8,
      image: img8,
      imageBg: img8,
      title: 'Carrinho 01',
      text: 'Nossa frota são de guindastes novos e semi- novos de última geração, com operadores capacitados trazendo mais segurança aos nossos clientes.',
    },
    {
    id: 9,
    image: img9,
    imageBg: img9,
    title: 'Carrinho 01',
    text: 'Nossa frota são de guindastes novos e semi- novos de última geração, com operadores capacitados trazendo mais segurança aos nossos clientes.',
  },

  ];

  export default items;