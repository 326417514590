import img1 from '../assets/img/Guindautos/1.jpeg';
import img2 from '../assets/img/Guindautos/2.jpeg';
import img3 from '../assets/img/Guindautos/3.jpeg';
import img4 from '../assets/img/Guindautos/4.jpeg';
import img5 from '../assets/img/Guindautos/5.jpeg';
import img6 from '../assets/img/Guindautos/6.jpeg';
import img7 from '../assets/img/Guindautos/7.jpeg';
import img8 from '../assets/img/Guindautos/8.jpeg';
import img9 from '../assets/img/Guindautos/9.jpeg';
import img10 from '../assets/img/Guindautos/10.jpeg';
import img11 from '../assets/img/Guindautos/11.jpeg';
import img12 from '../assets/img/Guindautos/12.jpeg';
import img13 from '../assets/img/Guindautos/13.jpeg';
import img14 from '../assets/img/Guindautos/14.jpeg';
import img15 from '../assets/img/Guindautos/15.jpeg';
import img16 from '../assets/img/Guindautos/16.jpeg';
import img17 from '../assets/img/Guindautos/17.jfif';
import img18 from '../assets/img/Guindautos/18.jfif';
import img19 from '../assets/img/Guindautos/19.jfif';
import img20 from '../assets/img/Guindautos/20.jfif';

const items = [
    {
      id: 1,
      image: img1,
      imageBg: img1,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 2,
      image: img2,
      imageBg: img2,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 3,
      image: img3,
      imageBg: img3,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 4,
      image: img4,
      imageBg: img4,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 5,
      image: img5,
      imageBg: img5,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 6,
      image: img6,
      imageBg: img6,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 7,
      image: img7,
      imageBg: img7,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 8,
      image: img8,
      imageBg: img8,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 9,
      image: img9,
      imageBg: img9,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 10,
      image: img10,
      imageBg: img10,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 11,
      image: img11,
      imageBg: img11,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 12,
      image: img12,
      imageBg: img12,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 13,
      image: img13,
      imageBg: img13,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 14,
      image: img14,
      imageBg: img14,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 15,
      image: img15,
      imageBg: img15,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 16,
      image: img16,
      imageBg: img16,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 17,
      image: img17,
      imageBg: img17,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 18,
      image: img18,
      imageBg: img18,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 19,
      image: img19,
      imageBg: img19,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
    {
      id: 20,
      image: img20,
      imageBg: img20,
      title: 'Carrinho 01',
      text: 'Temos guindastes articulados, semi novos com maior capacidade de carga e lança do mercado. Caminhões de carroceria, que agregam agilidade e benefício ao nossos clientes.',
    },
  ];

  export default items;